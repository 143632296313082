.drop-area {
	.dropzone {
		min-height: 125px;
		border: none;
	}
	section {
		position: relative;
		width: 100%;
		border: 2px dashed #C80C0F;
		.inner-text.has-image {
			border-radius: 15px;
			background-color: #00000033;
			p{
				color: #ffffff;
			}
			.icon {
                svg {
                    color: #ffffff;
                    height: 50px;
                }
            }
		}
		.inner-text {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			text-align: center;
			padding: 20px;
            .icon {
                svg {
                    color: #C80C0F;
                    height: 50px;
                }
            }
			.button-link {
				background-color: transparent;
				padding: 0;
				border: 0;
				border-radius: 0px;
				color: #C80C0F ;
				font-weight: 600;
				font-size: 1em;
				cursor: pointer;
				display: inline;
				margin-top: 10px;
			}
			.preview {
				border-radius: 20px;
				background: linear-gradient(to bottom, #eee, #ddd);
				padding: 10px 20px;;
				position: relative;
				display: block;
				z-index: 10;
				text-align: center;
				width: 120px;
				.details {
					.filename{
						text-overflow: ellipsis;
    					overflow: hidden;
					}
				}
				.progress {
					margin-top: 10px;
					z-index: 1000;
					pointer-events: none;
					background-color: #ffffff;
					.upload {
						background: linear-gradient(to bottom, #ea764d, #f15a24);
						transition: width 300ms ease-in-out;
					}
				}
				.dz-progress {
					margin-top: 15px;
				}
			}
			
		}
	}
}

.colours__menu {
	.colours__menu-list {
		.colours__option {
			// position: relative;
			// height: 30px;
			padding: 0;
			margin: 0;
			.colours-item {
				padding: 10px 5px;
				border-bottom: .2px solid #949494;
			}
		}
	}
}

.loader-container {
	position: fixed;
	left: 57%;
}

.carousel {
	.flickity-viewport {
		height: 400px !important;
		img {
			width: 300px;
			display: block;
			margin: auto;
		}
	}
}

.custom-control.custom-control-size {
	.custom-control-label {
		color: #767676;
		cursor: pointer;
		min-width: 3rem;
		padding: .5rem;
		text-align: center;
		border: 1px solid #ddd;
		position: relative;
		margin-bottom: 0;
		vertical-align: top;
	}
	.custom-control-label::before {
		top: 0;
		left: 0;
		opacity: 0;
	}
	.custom-control-input:checked~.custom-control-label {
		color: #111;
	}
	.custom-control-input:checked~.custom-control-label {
		border-color: #111;
	}
} 