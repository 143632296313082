@media only screen and (max-width: 600px){ 
    .page-body-wrapper{
        .login-container {
            .form-container {
                width: 95vw;
            }
       }
    }
}

.login-container {
    #triangle-topright {
        width: 0;
        height: 0;
        border-top: 100px solid white;
        border-left: 100px solid transparent;
    }
    
    // background-color: #C80C0F;
    background-image: url(../../public/images/Background.png);
    background-size: 100% 100%;
    min-height: 100vh;
    // background-image: linear-gradient(to bottom left, #ffffff 20%, #000000 21%, #C80C0F 13%);
    .logo-container {
        img {
            width: 320px;
        }
    }
    .form-container {
        background-color: #ffffff;
        width: 405px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 30px;
        .forgot-keepme{
            padding: .35714em 0 0;
            margin: 0 0 1.71429em;
            .forgot-link{
                display: block;
                font-size: 13px;
                line-height: normal;
                padding-top: 3px;
                color: #8c8c8c;
                a{
                    color: #8c8c8c;
                    &:hover, &:focus{
                        color: #C80C0F;
                    }
                }
            }
        }
        .keep-me{
            text-align: right;
            label{
                margin: 0;
                position: relative;
                cursor: pointer;
                .text{
                    display: inline-block;
                    position: relative;
                    padding-left: 20px;
                    span{
                        font-size: 13px;
                        // color: $authentication_keep_me_text;
                        border-bottom: 1px solid;
                        // border-color: $authentication_keep_me_text;
                    }
                }
            }
        }
        .check-box {
            .text{
                &:before{
                    top: 5px;
                }
            }
        }
        
        .input-group {
            width: 100%;
            border: .07143em solid #dcdcdc;
            background-color: #fff;
            border-radius: 5px;
            padding: 0;
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
            .input-group-text {
                border: 0;
                background: initial;
                img {
                    width: 1.28571em;
                    height: 1em;
                }
            }
            input {
                outline: none;
                box-shadow: none;
                border: 0;
                padding: 0;
            }
            .form-control {
                border: .07143em solid transparent;
                padding-left: 0;
                background-color: #fff;
                color: #8c8c8c;
                font-size: 1em;
            }
        }

        .action{
            display: block;
            border-top: 1px solid;
            border-color: #e8e8e8;
            padding-top: 22px;
            .submit{
                display: block;
                width: 100%;
                background-color: #C80C0F;
                color: #ffffff;
                font-size: 15px;
                font-weight: 600;
                border: 0;
                padding: 12px 15px;
                line-height: normal;
                cursor: pointer;
                margin-bottom: 15px;
                // @include transition(all 0.8s ease-in-out);
                // @include border-radius(5px);
                transition: all 0.8s ease-out;
                border-radius: 5px;
                &:hover, &:focus{
                    // background-color: $authentication_action_submit_bg_hover;
                    outline: none;
                    box-shadow: none;
                }
            }
            .signup{
                display: block;
                font-size: 13px;
                // color: $authentication_signup_text;
                font-weight: 400;
                text-align: center;
                line-height: normal;
                a{
                    font-weight: 600;
                    // color: $authentication_signup_text;
                    margin-left: 3px;
                    display: inline-block;
                    // @include transition(all 0.5 ease-in-out);
                    &:hover, &:focus{
                        // color: $authentication_signup_text_hover;
                    }
                }
            }
        }
    }
}