.page-store-details {
    .nav-vertical {
        li {
            a.active {
                color: #C80C0F;
                font-size: 14px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                font-weight: 600;
            }
            a {
                font-size: 14px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                font-weight: 600;
                color: #313131;
            }
        }
    }

    .preview {
        .mb-7, .my-7 {
            margin-bottom: 2rem !important;
        }
        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 0 solid rgba(17, 17, 17, 0.125);
            
        }
        .card-badge-left {
            left: 0;
        }
        .card-badge {
            position: absolute;
            top: 1.25rem;
            z-index: 1;
        }
        .card > * {
            flex-shrink: 0;
        }
        .badge-white {
            background-color: #fff;
            color: #111;
        }
        .badge {
            letter-spacing: .02em;
        }
        .badge {
            display: inline-block;
            padding: .5em 1em;
            font-size: .6875em;
            font-weight: 500;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .card-img {
            position: relative;
            width: 100%;
            a:not([href]) {
                color: inherit;
                text-decoration: none;
            }
            img {
                vertical-align: middle;
                border-style: none;
            }
            .card-actions {
                position: absolute;
                bottom: 0;
                z-index: 1;
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 1.25rem;
                .card-action {
                    position: absolute;
                    top: 1.25rem;
                    z-index: 1;
                }
                .card-action {
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 0;
                    opacity: 0;
                    transform: translateY(0.5rem);
                    transition: all .1s ease-in-out;
                    transition-property: transform,opacity;
                   
                }
            }

           
        }

        .card-body {
            .text-decoration-line-through {
                text-decoration: line-through !important;
            }

            .text-gray-350 {
                color: #a6a6a6 !important;
            }
            .font-size-xs {
                font-size: 0.875rem !important;
            }
        }
        .card-img:hover .card-actions .card-action {
            opacity: 1;
            transform: translateY(0);
        }
        .card-img, .card-img-bottom, .card-img-top {
            flex-shrink: 0;
            width: 100%;
        }
        

        h3 {
            font-weight: 500;
            line-height: 1.2;
            font-size: 1.75rem;
            color: #111;
        }
        .breadcrumb {
            display: flex;
            flex-wrap: wrap;
            padding: 0 0;
            margin-bottom: 1.5rem;
            list-style: none;
            background-color: transparent;
        }
        .text-gray-400 {
            color: #909090 !important;
        }
        .mb-7, .my-7 {
            margin-bottom: 2rem !important;
        }
        .nav-vertical {
            flex-direction: column;
            .mb-6{
                margin-bottom: 1.5rem !important;
            }
            .nav-link.active {
                color: #C80C0F ;
            }
            .nav-link {
                display: block;
                padding: .5rem 1rem;
                padding-left: 0;
                padding-right: 0;
                font-weight: 500;
                color: #111;
            }
            .font-size-lg {
                font-size: 1.125rem;
            }
            a{    
                transition: all .1s ease-in-out;
                transition-property: color;
                text-decoration: none;
                background-color: transparent;
            }

            .nav-link.active {
                position: relative;
                color: #C80C0F ;
            }

            .dropdown-toggle, .dropright-toggle {
                overflow: hidden;
                white-space: nowrap;
            }
            .text-reset {
                color: inherit;
            }

            .dropdown-toggle[data-toggle=collapse]::after {
                transition: all .1s ease-in-out;
                transform-origin: center center;
            }
            .dropdown-toggle::after {
                content: "";
                
            }
            .dropdown-toggle::after, .dropright-toggle::after {
                float: right;
                margin-left: .255em;
                font-family: FontAwesome;
                border: 0;
            }
            .list-styled {
                list-style-type: none;
                padding-left: 0;
            }
            .list-styled-link {
                display: block;
                color: #525252;
            }
            .list-styled {
                list-style-type: none;
                padding-left: 0;
            }
            .list-styled-item {
                display: block;
            }
            .list-styled-item + .list-styled-item {
                margin-top: .75em;
            }

        }

        .pagination {
            margin-bottom: 0;
            font-weight: 500;
            display: flex;
            padding-left: 0;
            list-style: none;
            .page-link {
                position: relative;
                display: block;
                padding: .75rem 1.25rem;
                margin-left: -1px;
                line-height: 1.25;
                color: inherit;
                background-color: transparent;
                border: 1px solid #e5e5e5;
                border-width: 0 0 1px 0;
            }
            .page-item:first-child {
                .page-link {
                    margin-left: 0;
                }
            }
            .page-link-arrow {
                border-width: 0;
            }

            .page-item.active {
                .page-link {
                    z-index: 3;
                    color: #C80C0F ;
                    background-color: transparent;
                    border-color: #C80C0F ;
                }
            }
        }
        .pagination-sm {
            font-size: .9375rem;
            .page-link {
                padding: .5rem 1rem;
                font-size: .9375rem;
                line-height: 1.5;
            }
        }

        .text-gray-400 {
            color: #909090 !important;
        }
      
    }
}