@import "./public/css/fontawesome.css";
@import "./public/css/jsgrid.css";

$theme-colors: (
  "primary": #C80C0F,
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "./public/css/admin.css";


.cursor-pointer {
  cursor: pointer;
}