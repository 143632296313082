.ReactTable {
    .rt-resizable-header-content {
        font-size: 1em;
        color: #1f1f1f;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
        // padding: 10px;
    }
    
    .rt-tr-group:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
    .rt-tr-group {
        cursor: pointer;
    }

    .rt-tbody, .rt-thead {
        .rt-tr {
            border: 1px solid #ebf1ff;

            .rt-td {
                // padding: 10px;
                display: flex;
                align-items: center;
                white-space: normal;
                img{
                    width: 50px;
                }
                div.select-categories{
                    width: 100%;
                }
                .categories__menu {
                    position: relative;
                }
            }
            .rt-th {
                padding: 1.07143em 1.42857em;
            }
        }
        .rt-tr.-odd {
            background-color: #f1f4fb80;
        }
    }
    .pagination-bottom {
        display: inline-block;
        border: .07143em solid #fff;
        text-align: left;
        background-color: #fff;
        .Table__nextPageWrapper, .Table__prevPageWrapper, .Table__visiblePagesWrapper {
            display: inline-block;
            vertical-align: top;
        }
        .Table__pageButton {
            background-color: #fff;
            color: #7b7b7b;
            padding: 1.07143em .71429em!important;
            font-size: 1.14286em;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            border: 0 solid;
            line-height: 1;
            font-weight: 600;
            position: relative;
            background: inherit!important;
            margin: 0!important;
        }
        .Table__pageButton.Table__pageButton--active {
            color: #C80C0F;
            border: 0;
        }
        .Table__pageButton.Table__pageButton--active:after {
            background-color: #C80C0F;
        }
        
        .Table__pageButton:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 0;
            width: 1.42857em;
            height: .21429em;
            background-color: #fff;
        }
        .normal-select {
            line-height: normal;
            border-radius: 0;
            font-size: 1em;
            padding: .35714em 2.5em .35714em .92857em;
            display: inline-block;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: .07143em solid #e5e5e5;
            color: #434343;
            font-weight: 400;
            background: #fff url(../../public/images/select-arrow.png) no-repeat 100%;
        }
        select {
            border: .07143em solid #dcdcdc;
            font-size: 1em;
            color: #8c8c8c;
            height: 2.85714em;
            padding: 0 1em;
            width: 100%;
            background-color: #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: url(../../public/images/select-arrow.png) no-repeat 100%;
        }
        
    }
}