@charset "UTF-8";

.jsgrid {
  position: relative;
  overflow: hidden;
  font-size: 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jsgrid * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jsgrid :after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jsgrid :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jsgrid input {
  font-size: 1em;
}

.jsgrid select {
  font-size: 1em;
}

.jsgrid textarea {
  font-size: 1em;
}

.jsgrid-grid-header {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.jsgrid-grid-body {
  overflow-x: auto;
  overflow-y: scroll;
}

.jsgrid-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.jsgrid-cell {
  padding: .5em;
}

.jsgrid-header-cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: .5em;
}

.jsgrid-сell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jsgrid-align-left {
  text-align: left;
}

.jsgrid-align-center {
  text-align: center;
}

.jsgrid-align-center input {
  text-align: center;
}

.jsgrid-align-center select {
  text-align: center;
}

.jsgrid-align-center textarea {
  text-align: center;
}

.jsgrid-align-right {
  text-align: right;
}

.jsgrid-align-right input {
  text-align: right;
}

.jsgrid-align-right select {
  text-align: right;
}

.jsgrid-align-right textarea {
  text-align: right;
}

.jsgrid-edit-row input {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-edit-row select {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-edit-row textarea {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-edit-row input[type=checkbox] {
  width: auto;
}

.jsgrid-filter-row input {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-filter-row select {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-filter-row textarea {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-filter-row input[type=checkbox] {
  width: auto;
}

.jsgrid-insert-row input {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-insert-row select {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-insert-row textarea {
  width: 100%;
  padding: .3em .5em;
}

.jsgrid-insert-row input[type=checkbox] {
  width: auto;
}

.jsgrid-header-sort {
  cursor: pointer;
}

.jsgrid-pager {
  padding: .5em 0;
}

.jsgrid-pager-nav-button {
  padding: .2em .6em;
}

.jsgrid-pager-nav-inactive-button {
  display: none;
  pointer-events: none;
}

.jsgrid-pager-page {
  padding: .2em .6em;
}

.jsgrid-selected-row .jsgrid-cell {
  cursor: pointer;
}

.jsgrid-nodata-row .jsgrid-cell {
  padding: .5em 0;
  text-align: center;
}

.jsgrid-grid-body {
  border: 1px solid #e9e9e9;
  border-top: none;
}

.jsgrid-grid-body .jsgrid-cell:first-child {
  border-left: none;
}

.jsgrid-grid-body .jsgrid-cell:last-child {
  border-right: none;
}

.jsgrid-grid-body .jsgrid-alt-row:first-child .jsgrid-cell {
  border-top: none;
}

.jsgrid-grid-body .jsgrid-row:first-child .jsgrid-cell {
  border-top: none;
}

.jsgrid-grid-header {
  border: 1px solid #e9e9e9;
  background: #f9f9f9;
}

.jsgrid-header-row .jsgrid-align-left {
  text-align: center;
}

.jsgrid-header-row .jsgrid-align-right {
  text-align: center;
}

.jsgrid-header-row .jsgrid-header-sort {
  background: #c4e2ff;
}

.jsgrid-header-row>.jsgrid-header-cell {
  border: 1px solid #e9e9e9;
  border-top: 0;
  background: #f9f9f9;
  border-bottom: 0;
}

.jsgrid-header-row>.jsgrid-header-cell:first-child {
  border-left: none;
}

.jsgrid-header-row>.jsgrid-header-cell:last-child {
  border-right: none;
}

.jsgrid-header-scrollbar {
  scrollbar-arrow-color: #f1f1f1;
  scrollbar-base-color: #f1f1f1;
  scrollbar-3dlight-color: #f1f1f1;
  scrollbar-highlight-color: #f1f1f1;
  scrollbar-track-color: #f1f1f1;
  scrollbar-shadow-color: #f1f1f1;
  scrollbar-dark-shadow-color: #f1f1f1;
}

.jsgrid-header-scrollbar::-webkit-scrollbar {
  visibility: hidden;
}

.jsgrid-header-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.jsgrid-cell {
  border: 1px solid #f3f3f3;
}

.jsgrid-invalid input {
  background: #ffe3e5;
  border: 1px solid #ff808a;
}

.jsgrid-invalid select {
  background: #ffe3e5;
  border: 1px solid #ff808a;
}

.jsgrid-invalid textarea {
  background: #ffe3e5;
  border: 1px solid #ff808a;
}

.jsgrid-pager-current-page {
  font-weight: 700;
}

.jsgrid-button:hover {
  opacity: .5;
  -webkit-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
}

.jsgrid-button+.jsgrid-button {
  margin-left: 5px;
}

.jsgrid .jsgrid-button {
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  /* background-image: url(../images/js-grid.png); */
  background-repeat: no-repeat;
  background-color: transparent;
}

.jsgrid .jsgrid-mode-button {
  width: 24px;
  height: 24px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
  .jsgrid .jsgrid-button {
    /* background-image: url(../images/js-grid.png); */
    background-size: 24px 352px;
  }
}

.jsgrid-mode-on-button {
  opacity: .5;
}

.jsgrid-cancel-edit-button {
  background-position: 0 0;
  width: 16px;
  height: 16px;
}

.jsgrid-clear-filter-button {
  background-position: 0 -40px;
  width: 16px;
  height: 16px;
}

.jsgrid-delete-button {
  background-position: 0 -80px;
  width: 16px;
  height: 16px;
}

.jsgrid-edit-button {
  background-position: 0 -120px;
  width: 16px;
  height: 16px;
}

.jsgrid-insert-mode-button {
  background-position: 0 -160px;
  width: 24px;
  height: 24px;
}

.jsgrid-insert-button {
  background-position: 0 -208px;
  width: 16px;
  height: 16px;
}

.jsgrid-search-mode-button {
  background-position: 0 -248px;
  width: 24px;
  height: 24px;
}

.jsgrid-search-button {
  background-position: 0 -296px;
  width: 16px;
  height: 16px;
}

.jsgrid-update-button {
  background-position: 0 -336px;
  width: 16px;
  height: 16px;
}

.jsgrid-load-shader {
  background: #ddd;
  opacity: .5;
  filter: alpha(opacity=50);
}

.jsgrid-load-panel {
  width: 15em;
  height: 5em;
  background: #fff;
  border: 1px solid #e9e9e9;
  padding-top: 3em;
  text-align: center;
}

.jsgrid-load-panel:before {
  content: ' ';
  position: absolute;
  top: .5em;
  left: 50%;
  margin-left: -1em;
  width: 2em;
  height: 2em;
  border: 2px solid #009a67;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: indicator 1s linear infinite;
  animation: indicator 1s linear infinite;
}

@-webkit-keyframes indicator {
  from {}

  50% {}

  to {}
}

@keyframes indicator {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.jsgrid-edit-row>.jsgrid-cell {
  border: 1px solid #e9e9e9;
  background: #fdffe3;
}

.jsgrid-filter-row>.jsgrid-cell {
  border: 1px solid #e9e9e9;
  border-bottom: 0;
  background: #f9f9f9;
}

.jsgrid-filter-row>.jsgrid-cell:first-child {
  border-left: none;
}

.jsgrid-filter-row>.jsgrid-cell:last-child {
  border-right: none;
}

.jsgrid-insert-row>.jsgrid-cell {
  border: 1px solid #e9e9e9;
  border-bottom: 0;
  background: #e3ffe5;
}

.jsgrid-insert-row>.jsgrid-cell:first-child {
  border-left: none;
}

.jsgrid-insert-row>.jsgrid-cell:last-child {
  border-right: none;
}

.jsgrid-header-sortable:hover {
  cursor: pointer;
  background: #f9f9f9;
}

.jsgrid-header-sort:before {
  content: " ";
  display: block;
  float: right;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: 8px;
  margin-right: 9px;
}

.jsgrid-header-sort-asc:before {
  border-width: 0 5px 5px;
  border-color: transparent transparent #ffc412;
}

.jsgrid-header-sort-desc:before {
  border-width: 5px 5px 0;
  border-color: #ffc412 transparent transparent;
}

.jsgrid-row>.jsgrid-cell {
  background: #fff;
}

.jsgrid-alt-row>.jsgrid-cell {
  background: #f9f9f9;
}

.jsgrid-selected-row>.jsgrid-cell {
  background: #c4e2ff;
  border-color: #c4e2ff;
}

.jsgrid-nodata-row>.jsgrid-cell {
  background: #fff;
}

.jsgrid-pager-nav-inactive-button a {
  color: #d3d3d3;
}