.awaiting-shipments{
    .actions {
        display: none;
    }

    .rt-tbody .rt-tr-group:hover .actions {
        display: block;
    }

    
}