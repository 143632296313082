.page-sidebar {
    .logo-wrapper {
        img {
            height: 130px;
            width: 130px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}