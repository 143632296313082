.item-details-page {
	.drop-area {
		.dropzone {
			min-height: 125px;
			border: none;
		}
		section {
			position: relative;
			width: 100%;
			border: 2px dashed #C80C0F ;
			border-radius: 15px;
			img {
				// width: 100%;
				max-height: 400px;
			}
			.inner-text {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				margin: 0;
				text-align: center;
				.icon {
					svg {
						color: #C80C0F;
						height: 50px;
					}
				}
				.button-link {
					background-color: transparent;
					padding: 0;
					border: 0;
					border-radius: 0px;
					color: #C80C0F ;
					font-weight: 600;
					font-size: 1em;
					cursor: pointer;
					display: inline;
					margin-top: 10px;
				}
				.preview {
					border-radius: 20px;
					background: linear-gradient(to bottom, #eee, #ddd);
					padding: 10px 20px;;
					position: relative;
					display: block;
					z-index: 10;
					text-align: center;
					width: 120px;
					.details {
						.filename{
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					.progress {
						margin-top: 10px;
						z-index: 1000;
						pointer-events: none;
						background-color: #ffffff;
						.upload {
							background: linear-gradient(to bottom, #ea764d, #f15a24);
							transition: width 300ms ease-in-out;
						}
					}
					.dz-progress {
						margin-top: 15px;
					}
				}
				
			}
		}
	}
	
	.colours {
		.item-container {
			.item {
				background: rgb(217, 227, 240);
				height: 22px;
				width: 22px;cursor: pointer;
				outline: none;
				margin-right: 10px;
				// margin-bottom: 10px;
				position: relative;
				top: 5px;
				border-radius: 4px;
				display: inline-block;
			}
		}
	}
	
	.custom-overlay {
		display: block;
		.custom-modal {
			padding: 0;
		}
	}
	
	@media (min-width: 576px) {
		.custom-overlay {
			.custom-modal {
				max-width: 350px;
			}
		}
	
	}
}